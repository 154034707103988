import React from 'react'
import hairLenPic from './homa.jpg'

export default function Praktikant() {
    return (
        <>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1
        }}>
            <h2>Ny Personal</h2>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", flexGrow:"yes" }}>
                <img src={hairLenPic} alt="hårlängd" height={250} width={150} style={{ marginRight: 10 }} />

                <p>
                Homa har en stor passion för färg och form och älskar att vara kreativ och få skapa.{ window.innerWidth > 500 ? <br/> : null }Med över 20 års yrkeserfarenhet både nationellt och internationellt, har hon stor kunskap i yrkets alla delar.{ window.innerWidth > 500 ? <br/> : null } Oavsett ålder oc h smak, hjälper Homa dig mer än gärna i sin stol!
                </p>
            </div>
        </div>
        {/* <p style={{backgroundColor: "whitesmoke", padding:10, borderRadius: 20}}><b>Kunder är varmt välkomna att boka klipp och slingning/helfärgning till ett förmånligare pris hos mig just nu med <u>25% rabatt!</u></b></p> */}
        </>
    )
}
